.cursor_primary {
  top: -20px;
  left: -20px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  user-select: none;
  z-index: 500;
  height: 40px;
  width: 40px;
  opacity: 0.7;
  border: 1px solid #fff;
  background-color: hsl(var(--foreground) / 0.2);
  transition: all 0.3s ease-out;
  will-change: width, height, transform, border;
  // transition-property: width, height, border;
  transform-origin: center;
  transition-duration: 250ms;

  &.pointer {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  &.logo {
    top: -40px;
    left: -40px;
    height: 80px;
    width: 80px;
    opacity: 1;
    background-color: hsl(var(--foreground));
    mix-blend-mode: difference;
  }

  &.go {
    opacity: 1;
    height: 57px;
    width: 57px;
    background: url(/layout/cursor/go.png);
    mix-blend-mode: normal;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.5);
    transition-duration: 350ms;
  }

  &.expanse {
    top: -50px;
    left: -50px;
    height: 100px;
    width: 100px;
    background-color: hsl(var(--foreground) / 0.1);
    transition-duration: 350ms;
  }

  &.scan {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  &.play {
    top: -90px;
    left: -160px;
    height: 180px;
    width: 320px;
    border: 1px solid hsl(var(--foreground) / 0.8);
    border-radius: 0;
    transition-duration: 150ms;
    transition: all 0.2s ease-out;
  }

  &.play:before {
    transform: translate(-50%, -50%);
    content: 'PLAY';
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.lens {
    top: calc(100vh / -7.6);
    left: calc(100vh / -7.6);
    height: calc(100vh / 3.8);
    width: calc(100vh / 3.8);
    background: no-repeat url(/layout/cursor/lens.png);
    background-size: cover;
    mix-blend-mode: normal;
    border: 1px solid rgba(255, 255, 255, 0.533);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.33s ease-out;
  }

  @media (max-width: 1199.98px) {
    display: none;
  }
}

.cursor_secondary {
  position: fixed;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  transition: all 0.1s ease-out;
  transition-property: width, height, border;
  will-change: width, height, transform, border;
  transform-origin: center;
  z-index: 500;
  mix-blend-mode: normal;

  &.pointer,
  &.logo,
  &.go,
  &.expanse,
  &.scan,
  &.lens {}

  &.play {
    top: -90px;
    left: -160px;
    height: 180px;
    width: 320px;
    border: 1px solid hsl(var(--foreground) / 1);
    border-radius: 0;
    background-color: hsl(var(--foreground) / 0.1);
    transition-duration: 500ms;
    transition: all 0.5s ease-out;
  }

  @media (max-width: 1199.98px) {
    display: none;
  }
}