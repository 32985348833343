.canvas_container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: var(--radius-md);
  background: hsl(var(--background) / 0.2);
  --webkit-backdrop-filter: blur(var(--blur-lg));
  backdrop-filter: blur(var(--blur-lg));
  display: flex;
  padding: 0 2rem;
}

.canvas_route_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

.canvas_route_main_item {
  position: relative;
  display: flex;
  color: hsl(var(--foreground));
  // opacity: 0.4;
  transition-duration: 1500ms;
  text-decoration: none;
  margin: 15px 0;
  h3 {
    position: relative;
    font-size: 6vw;
    font-weight: 800;
    margin-left: 38px;
    line-height: 6vw;
    // color: rgba(255, 255, 250, 0);
    -webkit-text-stroke: 2px hsl(var(--foreground));
    transition-duration: 500ms;
    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 10px;
      width: calc(100% - 20px);
      height: 2px;
      background: hsl(var(--foreground));
      transform: scaleX(0);
      transform-origin: right;
      transition: 0.5s transform;
      z-index: 50;
    }
    @media (max-width: 1199.98px) {
      // font-size: 64px;
      // line-height: 54px;
    }
    @media (max-width: 478.98px) {
      font-size: 7vw;
      line-height: 7vw;
      margin-left: 18px;
      -webkit-text-stroke: 0.5px hsl(var(--foreground));
      font-weight: 800;
    }
  }
  h6 {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 800;
    color: hsl(var(--foreground));
    opacity: 0.5;
    @media (max-width: 478.98px) {
      font-size: 8px;
    }
  }

  &:hover {
    opacity: 1;
    transition-duration: 250ms;
    cursor: pointer;
    h3 {
      // color: hsl(var(--primary));
      -webkit-text-stroke: 0px hsl(var(--foreground));
      letter-spacing: 3px;
      transition-duration: 250ms;
      transform: translateX(5%);
      &:before {
        transform: scaleX(1);
        transform-origin: left;
        transition: 0.5s transform;
      }
      @media (max-width: 478.98px) {
        letter-spacing: 1px;
        transform: translateX(5%);
      }
    }
    h6 {
      transition-duration: 250ms;
      opacity: 1;
      color: hsl(var(--primary));
    }
  }
}

.canvas_route_sub_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

.canvas_route_sub_item {
  position: relative;
  display: flex;
  color: hsl(var(--foreground));
  opacity: 0.4;
  transition-duration: 350;
  text-decoration: none;
  margin: 15px 0;
  will-change: transform;
  h3 {
    position: relative;
    font-size: 6vw;
    font-weight: 500;
    margin-left: 38px;
    line-height: 6vw;
    // cursor: none;
    color: rgba(255, 255, 250, 0);
    -webkit-text-stroke: 2px hsl(var(--foreground));
    transition-duration: 500ms;
  }
  &:hover {
    opacity: 1;
    transition-duration: 250ms;
    // cursor: none;
    h3 {
      color: hsl(var(--primary));
      -webkit-text-stroke: 0px hsl(var(--foreground));
      letter-spacing: 3px;
      transition-duration: 250ms;
      transform: translateX(5%);
    }
  }
}
