.canvas_container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: var(--radius-md);
  background: hsl(var(--background) / 0.2);
  --webkit-backdrop-filter: blur(var(--blur-lg));
  backdrop-filter: blur(var(--blur-lg));
  display: flex;
  padding: 0 2rem;
}

.canvas_inner_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  width: 100%;
}

.canvas_route_group_container {
  width: 100%;
}

.canvas_route_group_item {
  display: flex;
  color: hsl(var(--foreground));
  transition-duration: 1500ms;
  text-decoration: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  h3 {
    position: relative;
    font-size: 4vw;
    font-weight: 800;
    line-height: 4vw;
    color: hsl(var(--foreground));
    @media (max-width: 478.98px) {
    }
  }
  h6 {
    font-weight: 800;
    font-size: 2vw;
    color: hsl(var(--primary));
    @media (max-width: 478.98px) {
    }
  }
}

.canvas_route_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.canvas_route_item {
  padding: 1vw 2vw;
  margin: 0.5vw;
  opacity: 0.7;
  background-color: hsl(var(--foreground) / 0.1);
  border: solid 1px hsl(var(--foreground) / 0.2);
  border-radius: var(--radius-md);
  color: hsl(var(--foreground));
  text-decoration: none;
  text-wrap: nowrap;
  will-change: transform;
  h3 {
    font-size: 3vw;
    font-weight: 500;
    line-height: 3vw;
    cursor: none;
  }
}
